<template>
  <div class="rank-list-wrap">
    <el-form
      style="
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-100%);
      "
    >
      <template v-if="errorHint() == 'noerror' && category[getCurrentTab - 1] != 0">
        <!-- 類別下拉 -->
        <el-select
          v-model="category[getCurrentTab - 1][0].activeIndex"
          placeholder="請選擇類別"
          style="width: 140px"
          filterable
          @change="handleSelectCategory($event)"
        >
          <el-option
            v-for="(option, index) in category[getCurrentTab - 1][0].options"
            :key="index"
            :label="option"
            :value="index"
          ></el-option>
        </el-select>
        <!-- 搜尋bar -->
        <el-input
          v-if="getCurrentTab == 2 || getCurrentTab == 4"
          v-model="search_text"
          style="width: 200px; margin-left: 10px"
          :placeholder="
            getCurrentTab == 4
              ? '請輸入折扣名稱'
              : `請輸入${category[getCurrentTab - 1][0].options[category[getCurrentTab - 1][0].activeIndex]}名稱`
          "
          :prefix-icon="Search"
          clearable
          @keyup.enter="handleSearch"
          @clear="handleSearch"
        />
      </template>
      <el-button
        v-show="needDownloadBtn"
        type="primary"
        style="margin-left: 10px"
        :disabled="getDataPage == false"
        @click="downloadList"
      >
        下載CSV
      </el-button>
    </el-form>
    <!-- 排行榜 -->
    <div class="table-wrap" v-if="errorHint() == 'noerror'">
      <el-table
        ref="listTable"
        :data="getDataPage"
        height="100%"
        style="width: 100%"
        header-cell-class-name="cus-table-header"
        row-class-name="cus-table-row"
        empty-text="尚無資料"
      >
        <el-table-column
          v-for="(title, index) in table_title[getCurrentTab - 1]"
          :key="'title' + index"
          :prop="title.value"
          :label="title.label"
          :width="title.width"
        />
      </el-table>
    </div>
    <div class="gray-hint" v-if="errorHint() == '請選擇門市'">
      請先在上方條件篩選欄位選擇門市，
      <br />
      以查看相關資訊
    </div>
  </div>
</template>
<script>
import { ref, reactive, watch, computed } from 'vue';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import { useRouter } from 'vue-router';
import { Search } from '@element-plus/icons-vue';
import { downloadCSV } from '@/utils/download';

export default {
  name: 'ranking-list',
  setup() {
    const router = useRouter();
    const is_headquarters = sessionStorage.getItem('is_headquarters');
    const {
      getDataAll,
      getDataPage,
      getSearchCondition,
      getCurrentTab,
      getCurrentPage,
      getPageSize,
      getCurrentCategory,
      getSearchText,
    } = useGetters('analysis', [
      'getDataAll',
      'getDataPage',
      'getSearchCondition',
      'getCurrentTab',
      'getCurrentPage',
      'getPageSize',
      'getCurrentCategory',
      'getSearchText',
    ]);
    const { SET_PAGE_OPTION, SET_SEARCH_TEXT, SET_CCATEGORY } = useMutations('analysis', [
      'SET_PAGE_OPTION',
      'SET_SEARCH_TEXT',
      'SET_CCATEGORY',
    ]);
    const onePageData = useActions('analysis', ['onePageData']);
    const table_title = reactive([
      [
        { label: '排行', value: 'rank', width: '80' },
        { label: '門市', value: 'event_name', width: '' },
        { label: '銷售總淨額', value: 'total_amount', width: '120' },
        { label: '訂單筆數', value: 'total_count', width: '120' },
        { label: '銷售數', value: 'total_item_count', width: '120' },
        { label: '客單價', value: 'price', width: '120' },
      ],
      [
        { label: '排行', value: 'rank', width: '100' },
        { label: '品名', value: 'event_name', width: '' },
        { label: '銷售數', value: 'total_count', width: '200' },
        { label: '銷售總淨額', value: 'total_amount', width: '200' },
      ],
      [
        { label: '排行', value: 'rank', width: '100' },
        { label: '支付方式', value: 'event_name', width: '' },
        { label: '使用次數', value: 'total_count', width: '200' },
        { label: '累積金額', value: 'total_amount', width: '200' },
      ],
      [
        { label: '排行', value: 'rank', width: '100' },
        { label: '折扣概況', value: 'event_name', width: '' },
        { label: '使用次數', value: 'total_count', width: '200' },
        { label: '折扣金額', value: 'total_amount', width: '200' },
      ],
      [
        { label: '排行', value: 'rank', width: '80' },
        { label: '名稱', value: 'event_name', width: '' },
        { label: '銷售總淨額', value: 'total_amount', width: '120' },
        { label: '訂單筆數', value: 'total_count', width: '120' },
        { label: '銷售數', value: 'total_item_count', width: '120' },
        { label: '客單價', value: 'price', width: '120' },
      ],
    ]);

    // 產生各tab下拉類別
    const category = reactive([]);
    for (let i = 0; i < table_title.length; i++) {
      category.push([]);
    }
    function createCategoryOptions() {
      if (getCurrentTab.value == 2 || getCurrentTab.value == 4 || getCurrentTab.value == 5) {
        category[getCurrentTab.value - 1] = [];
        if (getDataAll.value.length != 0) {
          category[getCurrentTab.value - 1].push({
            activeIndex: 0,
            options: [],
          });
          for (let i = 0; i < getDataAll.value.length; i++) {
            category[getCurrentTab.value - 1][0].options.push(getDataAll.value[i].name);
          }
        }
      }
    }
    // 資料更新 > 下拉類別更新
    watch(
      () => getDataAll.value,
      () => {
        createCategoryOptions();
      },
      {
        immediate: true,
      }
    );
    // vuex 類別改變 > dom 類別更新
    watch(
      () => getCurrentCategory.value,
      () => {
        if (getCurrentTab.value != undefined) {
          if (category[getCurrentTab.value - 1] != 0)
            category[getCurrentTab.value - 1][0].activeIndex = category[getCurrentTab.value - 1][0].options.indexOf(
              getCurrentCategory.value[0]
            );
        }
      }
    );
    // 選擇類別
    const handleSelectCategory = async (cate) => {
      let dl = getDataAll.value.filter((data) => data.name == category[getCurrentTab.value - 1][0].options[cate])[0]
        .data.length;
      SET_CCATEGORY([category[getCurrentTab.value - 1][0].options[cate]]);
      SET_PAGE_OPTION({
        cp: 1,
        tp: dl <= getPageSize.value ? 1 : Math.ceil(dl / getPageSize.value),
        dl: dl,
      });
      router.push({ query: { tab: getCurrentTab.value, cate: cate + 1, page: getCurrentPage.value }, replace: true });
    };

    // 搜尋bar
    const search_text = ref('');
    search_text.value = getSearchText.value;
    watch(
      () => getSearchText.value,
      (newVal, oldVal) => {
        if (newVal != oldVal) search_text.value = getSearchText.value;
      }
    );
    // 搜尋文字
    const handleSearch = () => {
      SET_SEARCH_TEXT(search_text.value);
      let da = getDataAll.value.filter((data) => data.name == getCurrentCategory.value[0])[0].data;
      if (search_text.value.trim() != '')
        da = da.filter((data) => data.event_name.toLowerCase().includes(search_text.value.toLowerCase()));
      let dl = da.length;
      SET_PAGE_OPTION({
        cp: 1,
        tp: dl <= getPageSize.value ? 1 : Math.ceil(dl / getPageSize.value),
        dl: dl,
      });
      onePageData();
    };

    // 灰色提示字
    function errorHint() {
      if (getSearchCondition.value) {
        if (getSearchCondition.value.shop.id == '' && is_headquarters == 'true' && getCurrentTab.value != '1')
          return '請選擇門市';
        else return 'noerror';
      }
    }

    // 是否顯示"下載CSV"按鈕
    const needDownloadBtn = computed(() => {
      let isShow = false;
      if (errorHint() == 'noerror') {
        if (getCurrentTab.value == '1') {
          if (is_headquarters == 'true' && getSearchCondition.value.shop.id == '') isShow = true;
          else isShow = false;
        } else {
          if (is_headquarters == 'true' && getSearchCondition.value.shop.id == '') isShow = false;
          else isShow = true;
        }
      }
      return isShow;
    });
    // 下載CSV
    const doGetAnalysisCSVFile = useActions('analysis', ['doGetAnalysisCSVFile']);
    const downloadList = async () => {
      await doGetAnalysisCSVFile().then(function (csvContent) {
        downloadCSV(csvContent, '_銷售分析');
      });
    };

    return {
      getDataPage,
      getCurrentTab,
      category,
      table_title,
      search_text,
      Search,
      handleSearch,
      errorHint,
      handleSelectCategory,
      needDownloadBtn,
      downloadList,
    };
  },
};
</script>
<style lang="scss" scoped>
.rank-list-wrap {
  width: 100%;
  height: calc(100% - 88px);
  position: relative;
  .table-wrap {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}
.gray-hint {
  margin-top: 50px;
  text-align: center;
  width: 100%;
  color: var(--el-text-color-secondary);
}
</style>
